import styled from 'styled-components/macro';

export const TrueFalseStatementWrapper = styled.div`
  overflow: hidden;
  position: relative;
  padding-bottom: 4.5rem;
`;

export const TrueFalseStatementButtons = styled.div`
  background: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  position: absolute;
  right: 0;
  top: 4.5rem;
  z-index: 10;
`;

const TrueFalseStatementButton = styled.button<{ isAnswerErrorsShown: boolean; isSelected: boolean }>`
  font-weight: 700;
  background: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  border: 0.1rem solid ${({ theme }) => theme.colorV2.input.border.default};
  ${({ isAnswerErrorsShown, theme }) => isAnswerErrorsShown && `border-color: ${theme.colorV2.utilityError};`}
  color: ${({ theme }) => theme.colorV2.textQuaternary};
  font-size: 1.4rem;
  height: 4.2rem;
  transition: color 0.5s;
  width: 8rem;

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    color: ${({ theme }) => theme.colorV2.uiDarkest};
  }
`;

export const TrueStatementButton = styled(TrueFalseStatementButton)`
  ${({ isSelected, theme }) =>
    isSelected &&
    `
      background-color: ${theme.colorV2.utilitySuccess};
      color: ${theme.colorV2.textPrimary};
    `}
`;

export const FalseStatementButton = styled(TrueFalseStatementButton)`
  border-bottom-right-radius: 0.8rem;
  border-left: 0;
  position: relative;

  ${({ isSelected, theme }) =>
    isSelected &&
    `
      background-color: ${theme.colorV2.utilityError};
      color: ${theme.colorV2.uiLightest} !important;
    `}
`;
