import { useCallback } from 'react';
import styled, { css } from 'styled-components/macro';

import { ReactComponent as DownloadIcon } from '@components/CourseEdition/img/download.svg';
import { ReactComponent as LocalisationServiceIcon } from '@components/CourseEdition/img/localisation-service.svg';
import { ReactComponent as UploadIcon } from '@components/CourseEdition/img/upload.svg';
import { HeaderItemSeparator } from '@features/content/header';
import { HelpDisplayer } from '@features/help';
import { ContextualMenu } from '@features/theme';

import { ReactComponent as ContextualMenuIcon } from './_img/context-menu-trigger.svg';

const DownloadImage = styled(DownloadIcon)`
  margin-right: 1rem;
`;

const UploadImage = styled(UploadIcon)`
  margin-right: 1rem;
`;

const StyledContextualMenuIcon = styled(ContextualMenuIcon)`
  transform: scale(1.1);
  transform-origin: right;
  margin-right: 0.8rem;
`;

const StyledHelpDisplayer = styled(HelpDisplayer)`
  display: inline;
`;

export const NewMark = styled.span`
  ${({ theme }) => css`
    align-self: center;
    background-color: ${theme.colorV2.newMark.background};
    border-radius: 0.4rem;
    display: inline-block;
    color: ${theme.colorV2.textLight};
    font-size: 1rem;
    font-weight: 700;
    height: 1.8rem;
    line-height: 1.8rem;
    margin-left: ${theme.space.S};
    padding: 0 ${theme.space.S};
    text-transform: uppercase;
  `}
`;

type TranslationsMenuProps = {
  onTranslationsDownload: () => void;
  onTranslationsUpload?: () => void;
  onTranslationsUploadToLocalisationService?: () => void;
};

export const TranslationsMenu = ({
  onTranslationsDownload,
  onTranslationsUpload,
  onTranslationsUploadToLocalisationService,
}: TranslationsMenuProps) => {
  const getTranslationsMenuItems = useCallback(() => {
    const _translationsMenuItems = [
      <div key="_translations-download" onClick={onTranslationsDownload}>
        <DownloadImage />
        <span>Download translation request</span>
      </div>,
    ];

    if (onTranslationsUploadToLocalisationService) {
      _translationsMenuItems.push(
        <div key="_translations-upload-to-localisation-service" onClick={onTranslationsUploadToLocalisationService}>
          <LocalisationServiceIcon />
          <span>Upload to Smartling</span>
          <NewMark>New</NewMark>
        </div>,
      );
    }

    if (onTranslationsUpload) {
      _translationsMenuItems.push(
        <div key="_translations-upload" onClick={onTranslationsUpload}>
          <UploadImage />
          <span>Upload translations</span>
          <StyledHelpDisplayer type="guideline" id="guideline-upload-translations" />
        </div>,
      );
    }

    return _translationsMenuItems;
  }, [onTranslationsDownload, onTranslationsUpload, onTranslationsUploadToLocalisationService]);

  return (
    <>
      <ContextualMenu position="left" triggerIcon={<StyledContextualMenuIcon />} variant="small" withTrigger>
        {getTranslationsMenuItems()}
      </ContextualMenu>
      <HeaderItemSeparator />
    </>
  );
};
