import { useUser } from '@features/auth';
import { isFeatureEnabled } from '@helpers/featuresHelper';

export const useUploadTranslationsToLocalisationService = () => {
  const { user } = useUser();

  const shouldShowUploadTranslationsToLocalisationService = Boolean(
    isFeatureEnabled('smartling') && user && ['ROLE_ADMIN', 'ROLE_LOCALISATION_MANAGER'].includes(user.role),
  );

  return { shouldShowUploadTranslationsToLocalisationService };
};
