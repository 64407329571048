import type { CommonFilterOptionType } from '@components/SelectorCommonComponents';
import { CreatableSearchableSelector } from '@features/theme';

type LocalisationJobFilterProps = {
  disabled: boolean;
  options: CommonFilterOptionType[];
  value: CommonFilterOptionType | null;
  onCreate: (name: string) => void;
  onFilter: (value: CommonFilterOptionType | null) => void;
};

export const LocalisationJobFilter = ({ disabled, options, value, onCreate, onFilter }: LocalisationJobFilterProps) => {
  return (
    <CreatableSearchableSelector
      classNamePrefix="localisation-job-filter"
      disabled={disabled}
      options={options}
      placeholder="Enter job name"
      onChange={onFilter}
      onCreateOption={onCreate}
      onFocus={() => {}}
      value={value}
    />
  );
};
