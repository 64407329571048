import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Button, Text } from '@features/theme';
import { ReactComponent as SuccessIcon } from '@static/svg/searchSuccess.svg';

import { UPLOADING_TRANSLATIONS_PROGRESS_STEP } from './constants';
import { UploadingProgressCloseIcon, UploadingProgressContainer, UploadingProgressLoader } from './styles';
import { UploadTranslationsProgressStep } from './types';

type UploadTranslationsToLocalisationServiceProgressModalProps = {
  uploadTranslationPromise?: Promise<AxiosResponse>;
  onClose: () => void;
  onError: () => void;
};

export const UploadTranslationsToLocalisationServiceProgressModal = ({
  uploadTranslationPromise,
  onClose,
  onError,
}: UploadTranslationsToLocalisationServiceProgressModalProps) => {
  const [step, setStep] = useState<UploadTranslationsProgressStep>(UPLOADING_TRANSLATIONS_PROGRESS_STEP.UPLOADING);
  const theme = useTheme();

  useEffect(() => {
    if (uploadTranslationPromise) {
      uploadTranslationPromise
        .then((result) => {
          if (result.status === 204) {
            setStep(UPLOADING_TRANSLATIONS_PROGRESS_STEP.SUCCESS);
          }
        })
        .catch(() => {
          onError();
        });
    }
  }, [onError, uploadTranslationPromise]);

  return (
    <UploadingProgressContainer>
      <UploadingProgressCloseIcon onClick={onClose} />
      {step === 'uploading' && (
        <>
          <UploadingProgressLoader />
          <Text bold level={1} spaceBottom="S">
            Upload in progress...
          </Text>
          <Text color={theme.colorV2.textTertiary} level={2} spaceBottom="S">
            This content is being uploaded to Smartling for translation.
          </Text>
        </>
      )}
      {step === 'success' && (
        <>
          <SuccessIcon />
          <Text level={3} variant="header" spaceBottom="L" spaceTop="L">
            Translations request created!
          </Text>
          <Button onClick={onClose}>Close</Button>
        </>
      )}
    </UploadingProgressContainer>
  );
};
