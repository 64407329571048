import { AxiosResponse } from 'axios';
import { useAppDispatch } from '@redux/store';
import { useState } from 'react';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { IssuesToggler } from '@components/IssuesToggler';
import { useIsUploadTranslationsAvailable } from '@features/auth/roles';
import { getValueByLanguage } from '@features/content';
import {
  ButtonsList,
  ContentData,
  HeaderItemSeparator,
  HeaderWrapper,
  PendingChangesButton,
  SaveButton,
} from '@features/content/header';
import type { LanguageV2 } from '@features/content/languages';
import { ListPreviewModal, PreviewButton } from '@features/content/preview';
import { PreviewModalActionsCreator } from '@actionCreators/PreviewModalActionsCreator';
import ContentsService from '@services/ContentsService';

import { useCurrentGrammarContent } from '../useCurrentGrammarContent';
import { GrammarTopicContentType } from '../GrammarTopic';
import { PublishButton } from './PublishButton';
/** @TODO Use shared @features/content/translations/UploadDownloadTranslationsMenu */
import { TranslationsMenu } from './TranslationsMenu';
/** @TODO Refactor UploadTranslations component and move into @features/content/translations */
import { UploadTranslations } from '@components/UploadTranslations';
import { useShowDebugUI } from '@features/app/debug';
import { ValidationButton } from '@features/content/forceValidation';
import { useToast } from '@features/app/toast';
import {
  UploadTranslationsToLocalisationServiceModal,
  UploadTranslationsToLocalisationServiceProgressModal,
  useUploadTranslationsToLocalisationService,
} from '@features/content/translations';
import { Modal, useDialogModal } from '@features/modal';

type HeaderProps = {
  contentName: string;
  learningLanguage: LanguageV2;
  interfaceLanguages: LanguageV2[];
};

const ALLOWED_CONTENT_TYPES: string[] = [
  ContentTypes.grammarReview,
  ContentTypes.grammarCategory,
  ContentTypes.grammarTopic,
];
const convertToContentType = (type: ContentTypesType | ExerciseTypesType): ContentTypesType => {
  if (ALLOWED_CONTENT_TYPES.includes(type)) {
    return type as ContentTypesType;
  }

  // This component is only used for grammar branch of the content, so anything except ALLOWED_CONTENT_TYPES is an exercise
  return ContentTypes.exercise;
};

export const Header = ({ contentName, learningLanguage, interfaceLanguages }: HeaderProps) => {
  const [showTranslationsUploadModal, setShowTranslationsUploadModal] = useState(false);
  const [uploadTranslationToLocalisationServicePromise, setUploadTranslationToLocalisationServicePromise] = useState<
    Promise<AxiosResponse> | undefined
  >(undefined);

  const dispatch = useAppDispatch();
  const { grammarType, childrenArray, grammarContent, isGrammarContentLoaded, parentId, onSave } =
    useCurrentGrammarContent();
  const isUploadTranslationsAvailable = useIsUploadTranslationsAvailable();
  const showToast = useToast();

  const showDebugUI = useShowDebugUI();

  const { shouldShowUploadTranslationsToLocalisationService } = useUploadTranslationsToLocalisationService();

  const { open: openUploadToLocalisationServiceModal, modal: uploadTranslationsToLocalisationServiceModal } =
    useDialogModal((modalControls) => (
      <Modal lockScroll overflow="visible" size="M" {...modalControls}>
        <UploadTranslationsToLocalisationServiceModal
          isOpen={modalControls.isOpen}
          language={learningLanguage}
          onCancel={() => {
            modalControls.close();
            setUploadTranslationToLocalisationServicePromise(undefined);
          }}
          onUpload={(uploadTranslationPromise) => {
            modalControls.close();
            setUploadTranslationToLocalisationServicePromise(uploadTranslationPromise);
            openUploadTranslationsToLocalisationServiceProgressModal();
          }}
        />
      </Modal>
    ));

  const {
    open: openUploadTranslationsToLocalisationServiceProgressModal,
    modal: uploadTranslationsToLocalisationServiceProgressModal,
  } = useDialogModal((modalControls) => (
    <Modal lockScroll size="S" {...modalControls}>
      <UploadTranslationsToLocalisationServiceProgressModal
        uploadTranslationPromise={uploadTranslationToLocalisationServicePromise}
        onClose={modalControls.close}
        onError={() => {
          modalControls.close();
          openUploadToLocalisationServiceModal();
        }}
      />
    </Modal>
  ));

  const onTopicTranslationsDownload = () => {
    if (grammarContent.id) {
      ContentsService.csv.download(
        grammarContent.id,
        learningLanguage,
        interfaceLanguages,
        getValueByLanguage((grammarContent as GrammarTopicContentType).title?.textLocalizations),
        'grammarTopic',
      );

      showToast({
        type: 'info',
        title: 'Download should start automatically in few seconds',
      });
    }
  };

  const onTopicTranslationsUpload = () => {
    setShowTranslationsUploadModal(true);
  };

  const isDataForTopicPreviewReady = isGrammarContentLoaded && childrenArray.length > 0;

  return (
    <HeaderWrapper>
      {isDataForTopicPreviewReady && <ListPreviewModal />}
      <ContentData
        contentName={contentName}
        learningLanguage={learningLanguage}
        interfaceLanguages={interfaceLanguages}
      />
      <ButtonsList>
        {grammarType === ContentTypes.grammarTopic && (
          <>
            <TranslationsMenu
              onTranslationsDownload={onTopicTranslationsDownload}
              onTranslationsUpload={isUploadTranslationsAvailable ? onTopicTranslationsUpload : undefined}
              onTranslationsUploadToLocalisationService={
                shouldShowUploadTranslationsToLocalisationService ? openUploadToLocalisationServiceModal : undefined
              }
            />
            {isUploadTranslationsAvailable && (
              <UploadTranslations
                show={showTranslationsUploadModal}
                onHide={() => setShowTranslationsUploadModal(false)}
              />
            )}
            {uploadTranslationsToLocalisationServiceModal}
            {uploadTranslationsToLocalisationServiceProgressModal}
          </>
        )}
        {(grammarType === ContentTypes.exercise ||
          (grammarType === ContentTypes.grammarTopic && isDataForTopicPreviewReady)) && (
          <>
            <PreviewButton
              onPreviewClick={() => {
                if (grammarType === ContentTypes.exercise) {
                  dispatch(PreviewModalActionsCreator.openPreview());
                } else if (grammarType === ContentTypes.grammarTopic) {
                  dispatch(PreviewModalActionsCreator.openPreviewForExercisesList());
                }
              }}
            />
            <HeaderItemSeparator />
          </>
        )}
        {isGrammarContentLoaded === LoadingStage.loaded &&
          (showDebugUI && grammarContent.id ? (
            <ValidationButton id={grammarContent.id} type={convertToContentType(grammarContent.type)} />
          ) : (
            <IssuesToggler
              validation={
                Object.values(ExerciseTypes).includes(grammarContent.type as any)
                  ? (grammarContent as AnyExerciseContentInterface).validation
                  : grammarContent.validationStatus
              }
              type={grammarType}
              hasChildrenArrayInvalidItems={
                !!childrenArray.filter((children) => !children.validationStatus?.valid).length
              }
            />
          ))}
        <HeaderItemSeparator />
        <PendingChangesButton />
        <SaveButton onSave={onSave} />
        <PublishButton contentToPublish={grammarContent} parentId={parentId} childrenArray={childrenArray} />
      </ButtonsList>
    </HeaderWrapper>
  );
};
