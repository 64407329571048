import { UserInterface } from '@common/interfaces/users/UserInterface';
import { Role } from '@features/auth/roles';
import type { LanguageV2 } from '@features/content/languages';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { useAppSelector } from '@redux/store';
import { selectCourse } from '@selectors/CoursesSelectors';
import { selectVocabularyReview } from '@selectors/VocabularyReviewSelectors';

import { useUser } from '../hooks';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { VocabularyReview } from '@features/content/vocabularyReview';

/** Constants */

const ROLES_ALLOWED_TO_PUBLISH: Role[] = ['ROLE_ADMIN', 'ROLE_PUBLISHER'];
const ROLES_ALLOWED_TO_UPLOAD_TRANSLATIONS: Role[] = [
  'ROLE_ADMIN',
  'ROLE_LOCALISATION_MANAGER',
  'ROLE_PUBLISHER',
  'ROLE_EDITOR',
];
const ROLES_ALLOWED_TO_EDIT: Role[] = [
  'ROLE_ADMIN',
  'ROLE_PUBLISHER',
  'ROLE_UNPUBLISHER',
  'ROLE_EDITOR',
  'ROLE_TRAINEE',
];
const ROLES_ALLOWED_TO_ONLY_UNPUBLISH: Role[] = ['ROLE_ADMIN', 'ROLE_PUBLISHER', 'ROLE_UNPUBLISHER'];

/** Helpers */

const hasUserAccessToLanguage = (user: UserInterface, language: LanguageV2) => {
  return user.languages.all || user.languages.list.includes(language) || user.role === 'ROLE_ADMIN';
};

type ContentTypesForPublishing = CourseInterface | VocabularyReview;

const getPublishAvailability = ({
  currentContent,
  language,
  user,
}: {
  currentContent: ContentTypesForPublishing;
  language: LanguageV2;
  user: UserInterface | null;
}) => {
  if (!isFeatureEnabled('publication')) return false;

  let availabilityChecks = currentContent.id && user && hasUserAccessToLanguage(user, language);

  return Boolean(availabilityChecks);
};

/** Hooks */

export const useIsAdmin = () => {
  const { user } = useUser();

  return user?.role === 'ROLE_ADMIN';
};

export const useIsPublishAvailable = () => {
  const { user } = useUser();
  const currentCourse = useAppSelector(selectCourse);
  const currentVocabularyReview = useAppSelector(selectVocabularyReview);

  let currentContent: ContentTypesForPublishing = currentCourse;
  let language: LanguageV2 = currentCourse.learningLanguage as LanguageV2;

  if (window.location.pathname.includes('vocabulary-review')) {
    currentContent = currentVocabularyReview;
    language = currentVocabularyReview.language as LanguageV2;
  }

  const isPublishAvailable = getPublishAvailability({ currentContent, language, user });

  if (isPublishAvailable) {
    const currentUser = user as UserInterface;

    return {
      publish: ROLES_ALLOWED_TO_PUBLISH.includes(currentUser.role),
      unpublish: ROLES_ALLOWED_TO_ONLY_UNPUBLISH.includes(currentUser.role),
    };
  }

  return {
    publish: false,
    unpublish: false,
  };
};

export const useIsUploadTranslationsAvailable = () => {
  const { user } = useUser();
  const currentCourse = useAppSelector(selectCourse);

  if (user && currentCourse.id) {
    return (
      ROLES_ALLOWED_TO_UPLOAD_TRANSLATIONS.includes(user.role) &&
      hasUserAccessToLanguage(user, currentCourse.learningLanguage as LanguageV2)
    );
  }

  return false;
};

export const useIsEditAvailableForCourse = () => {
  const { user } = useUser();
  const currentCourse = useAppSelector(selectCourse);

  if (user) {
    const allowedToEdit = ROLES_ALLOWED_TO_EDIT.includes(user.role);

    if (currentCourse.id !== 'null') {
      return allowedToEdit && hasUserAccessToLanguage(user, currentCourse.learningLanguage as LanguageV2);
    } else {
      return allowedToEdit;
    }
  }

  return false;
};

export const useIsAccessCourseStats = () => {
  const { user } = useUser();

  if (user) {
    return (
      isFeatureEnabled('publication') &&
      (ROLES_ALLOWED_TO_PUBLISH.includes(user.role) || user.role === 'ROLE_COURSE_STATS_VIEWER')
    );
  }

  return false;
};
