import { Property } from 'csstype';
import { ReactNode, useEffect, useRef, MouseEvent } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components/macro';

import { useOnClickOutside } from './hooks';

type ModalSizeType = 'L' | 'M' | 'S';

const StyledModal = styled.dialog<{ overflow: Property.Overflow; size: ModalSizeType }>`
  ${({ overflow, size, theme }) => {
    let maxWidth;
    let minHeight;

    switch (size) {
      case 'L':
        maxWidth = 132.8;
        minHeight = 29;
        break;

      case 'M':
        maxWidth = 94.6;
        minHeight = 29;
        break;

      case 'S':
        maxWidth = 50;
        minHeight = 5;
        break;

      default:
        maxWidth = 50;
        minHeight = 5;
        break;
    }

    return css`
      border: none;
      box-shadow: 0 0.8rem 1.2rem 0.1rem rgba(0, 0, 0, 0.1);
      border-radius: 1.6rem;

      width: 90%;
      max-width: ${maxWidth}rem;
      min-height: ${minHeight}rem;

      overflow: ${overflow};
      padding: 4rem;

      &::backdrop {
        background-color: ${theme.colorV2.systemOverlayBlack};
      }
    `;
  }}
`;

const LockScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

type ModalProps = {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  lockScroll?: boolean;
  overflow?: Property.Overflow;
  size?: ModalSizeType;
  onClickOutside?: (e: MouseEvent<HTMLDialogElement>) => void;
};

export const Modal = ({
  children,
  className,
  isOpen,
  lockScroll = false,
  overflow = 'auto',
  size = 'S',
  onClickOutside,
}: ModalProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    isOpen ? dialogRef.current?.showModal() : dialogRef.current?.close();
  }, [isOpen]);

  const onClick = useOnClickOutside(dialogRef, onClickOutside ? onClickOutside : () => {});

  return (
    <StyledModal className={className} overflow={overflow} size={size} ref={dialogRef} onClick={onClick}>
      {lockScroll && isOpen && <LockScroll />}
      {children}
    </StyledModal>
  );
};
