/**
 * Normalize Date to YYYY-MM-DDTHH:mm needed for <input type="datetime-local" />
 * @see https://html.spec.whatwg.org/multipage/common-microsyntaxes.html#concept-datetime-local
 * @param datetime
 */

export const normalizeDatetime = (datetime: Date) => {
  const utcDatetime = datetime.toISOString();

  // Removes seconds, milliseconds and Z
  return utcDatetime.substring(0, utcDatetime.search(/:\w{2}\.\w{3}Z/));
};
