import { FiltersState, UploadTranslationsAPIParams } from '../types';

export const mapFiltersToUploadTranslationsPayload = (filters: FiltersState) => {
  const { dueDate, job, targetLanguages, projectId } = filters;
  let { id: jobId, name: jobName } = job;
  /**
   * A created job has the same value id and name (CreatableSearchableSelector constrain not allowing `undefined` or `null` for value)
   * If the job is a created one, set id null for Backend handling
   */
  jobId = job.id !== job.name ? job.id : null;

  const uploadTranslationsPayload = {
    dueDate,
    jobId,
    jobName,
    targetLanguages,
    projectId,
  };

  return uploadTranslationsPayload as Omit<UploadTranslationsAPIParams, 'translationsFile'>;
};
