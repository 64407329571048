import { apiClient } from '@features/api';

import type { StringSearchResultType } from '../SearchResultItem';
import type { StringQueryParamsType } from './types';

import { MAX_QUERY_CHARS_LENGTH } from '../constants';
import { truncateString } from '../util';

const DEFAULT_WITH_WITHOUT_VALUE = { with: [], without: [] };

const getQueryParams = ({
  languages,
  courses,
  translations,
  audios,
  isReused,
  hasKTags,
  isDefaultInstruction,
}: StringQueryParamsType) => {
  const { with: withTranslations, without: withoutTranslations } = translations || DEFAULT_WITH_WITHOUT_VALUE;
  const { with: withAudio, without: withoutAudio } = audios || DEFAULT_WITH_WITHOUT_VALUE;

  return {
    languages: languages || undefined,
    courses: courses || undefined,
    hasTextTranslations: withTranslations.length > 0 ? withTranslations : undefined,
    hasNoTextTranslations: withoutTranslations.length > 0 ? withoutTranslations : undefined,
    hasAudioTranslations: withAudio.length > 0 ? withAudio : undefined,
    hasNoAudioTranslations: withoutAudio.length > 0 ? withoutAudio : undefined,
    isReused: isReused !== null ? isReused : undefined,
    isDefaultInstruction: isDefaultInstruction !== null ? isDefaultInstruction : undefined,
    hasKTags: hasKTags !== null ? hasKTags : undefined,
  };
};

export const searchByString = async (
  query: string,
  page: number,
  filtersParams: StringQueryParamsType,
): Promise<{ data: { count: number; results: StringSearchResultType[] } }> => {
  // truncate query string to avoid a server error when long strings
  const sanitizedQuery = truncateString(query, MAX_QUERY_CHARS_LENGTH);

  return await apiClient.noErrorsV2.get('/search/strings', {
    params: {
      query: sanitizedQuery,
      page,
      ...getQueryParams(filtersParams),
    },
  });
};
