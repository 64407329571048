import { upperFirst } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components/macro';

import { EXERCISES, ExerciseType } from '@common/enums/ExerciseTypes';
import { DBId } from '@common/types/DBId';
import { Warning } from '@components/Warning';
import { NavigationItemType } from '@features/content/navigation';
import { Button, Loader } from '@features/theme';

import { prepareNewStructure } from './_util/prepareNewStructure';
import { ContentTree } from './ContentTree';

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-weight: 800;
  font-size: 2rem;
  margin-bottom: 0.8rem;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
`;

const StyledWarning = styled((props) => <Warning {...props} />)`
  align-items: flex-start;
  margin: 0;
  max-width: 90rem;
  padding: 1.2rem 1.6rem;

  svg {
    margin: 0;
  }
`;

const WarningContent = styled.ul`
  color: ${({ theme }) => theme.colorV2.textSecondary};
  font-size: 1.6rem;
  list-style: none;
  padding-left: 1.8rem;
`;

const WarningContentItem = styled.li`
  &:before {
    content: '·';
    line-height: 1.6rem;
    font-size: 3.2rem;
    margin-right: 0.8rem;
    vertical-align: top;
  }
`;

const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 50rem;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  justify-content: flex-end;
`;

type MoveContentModalContentPropsType = {
  busy: boolean;
  courseItem: NavigationItemType;
  courseStructure: NavigationItemType[];
  isOpen: boolean;
  selectedContent: NavigationItemType | null;
  onCancel: () => void;
  onConfirm: ({
    selectedContentId,
    currentParentId,
    newParentId,
    isExercise,
    newNavigationStructure,
  }: {
    selectedContentId: DBId;
    currentParentId: DBId;
    newParentId: DBId;
    isExercise: boolean;
    newNavigationStructure: NavigationItemType[];
  }) => void;
  onError: (error: Error) => void;
};

const ModalContentDescription = ({
  isExercise,
  selectedContent,
}: {
  isExercise: boolean;
  selectedContent: NavigationItemType | null;
}) => {
  if (!selectedContent) return null;

  const selectedContentTitleFallback = selectedContent?.type ? upperFirst(selectedContent.type) : 'this content';
  const selectedContentTitle = selectedContent?.title ?? selectedContentTitleFallback;

  return (
    <Description>
      Move <strong>{selectedContentTitle}</strong>
      {!isExercise ? ' and its children' : ''} by selecting a new location.
    </Description>
  );
};

export const MoveContentModalContent = ({
  busy,
  courseItem,
  courseStructure,
  isOpen,
  selectedContent,
  onCancel,
  onConfirm,
  onError,
}: MoveContentModalContentPropsType) => {
  const [selectedTargetLocationId, setSelectedTargetLocationId] = useState<DBId>();
  const [structure, setStructure] = useState<NavigationItemType[]>([]);

  const isExercise = EXERCISES.includes(selectedContent?.type as ExerciseType);

  const handleOnClickConfirm = () => {
    if (selectedContent && selectedContent.parentId && selectedTargetLocationId) {
      const currentParentId = selectedContent.parentId ? selectedContent.parentId : '';
      onConfirm({
        selectedContentId: selectedContent.id,
        currentParentId,
        newParentId: selectedTargetLocationId,
        isExercise,
        newNavigationStructure: prepareNewStructure({
          currentParentId,
          currentStructure: courseStructure,
          newParentId: selectedTargetLocationId,
          selectedContentId: selectedContent.id,
        }),
      });
    } else {
      onError(new Error('Error occured on confirming move content. Check if `selectedContent` is not null '));
    }
  };

  return (
    <ModalContentWrapper>
      <Title>Move content</Title>
      <ModalContentDescription isExercise={isExercise} selectedContent={selectedContent} />
      <StyledWarning>
        <WarningContent>
          <WarningContentItem>Content moved will be unpublished and removed from the old location.</WarningContentItem>
          <WarningContentItem>
            Content can only be moved to a valid layer in the hierarchy (lessons to chapters, activities to lessons,
            etc.)
          </WarningContentItem>
          <WarningContentItem>
            To publish your content in a new location, you will need to click on "Update" button.
          </WarningContentItem>
        </WarningContent>
      </StyledWarning>

      {busy ? (
        <LoaderWrapper>
          <Loader size="L" />
        </LoaderWrapper>
      ) : (
        <ContentTree
          courseItem={courseItem}
          isOpen={isOpen}
          navigationCourseStructure={courseStructure}
          selectedContent={selectedContent}
          selectedTargetLocationId={selectedTargetLocationId}
          structure={structure}
          setStructure={setStructure}
          onError={onError}
          onSelect={setSelectedTargetLocationId}
        />
      )}

      <ButtonsContainer>
        <Button
          variant="tertiary"
          onClick={() => {
            setSelectedTargetLocationId(undefined);
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button disabled={!selectedTargetLocationId} onClick={handleOnClickConfirm}>
          {selectedContent?.ready ? 'Unpublish & Move' : 'Move'}
        </Button>
      </ButtonsContainer>
    </ModalContentWrapper>
  );
};
