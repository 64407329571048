import { call, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { PreparedPublishContentInterface } from '@common/interfaces/contentTypes/PublishingInterface';
import { ContentTypes, ContentType } from '@common/enums/ContentTypes';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { PublishingActions } from '@actions/PublishingActions';
import PublishingService from '@services/PublishingService';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { apiClient } from '@features/api';
import { addToast } from '@features/app/toast';
import { selectCourse, selectPublishingPreparedContent } from '@selectors/CoursesSelectors';

const PublishingSagas = {
  *publishOrUnpublishContent({ payload }: any) {
    try {
      const preparedContent: PreparedPublishContentInterface = yield select(selectPublishingPreparedContent);

      const currentCourse: CourseInterface = yield select(selectCourse);

      const { readyState } = payload;

      let finalType = preparedContent.type;

      if (
        preparedContent.type === ContentTypes.exercise ||
        Object.keys(ExerciseTypes).find((exerciseType: string) => exerciseType === preparedContent.type)?.length
      ) {
        finalType = ContentTypes.exercise;
      } else if (
        preparedContent.type === ContentTypes.grammarReview ||
        preparedContent.type === ContentTypes.grammarCategory ||
        preparedContent.type === ContentTypes.grammarTopic ||
        preparedContent.type === ContentTypes.placementTest ||
        preparedContent.type === ContentTypes.entrypoint ||
        preparedContent.type === ContentTypes.roleplay ||
        preparedContent.type === ContentTypes.roleplayCategory ||
        preparedContent.type === ContentTypes.roleplayScenario ||
        preparedContent.type === ContentTypes.lexicalItem
      ) {
        finalType = preparedContent.type;
      } else if (preparedContent.type !== ContentTypes.course) {
        finalType = ContentType.group;
      }
      yield put({
        type: PublishingActions.SET_PUBLISHING_IN_PROGRESS,
        payload: true,
      });

      const togglePublishContent =
        readyState && !preparedContent.forUpdate
          ? PublishingService.unpublishContent
          : PublishingService.publishContent;

      const publishingResult: AxiosResponse = yield call(
        togglePublishContent,
        preparedContent.id,
        finalType,
        preparedContent.parentId,
        currentCourse.id,
        preparedContent.parentType,
        preparedContent.language,
      );

      if (publishingResult.status === 200 || publishingResult.status === 204) {
        yield put({
          type: PublishingActions.CHANGE_READY_STATE,
          payload: { contentType: finalType, id: preparedContent.id, forUpdate: preparedContent.forUpdate },
        });

        addToast({
          type: 'success',
          title: 'Added to pending changes queue',
          description: 'Content changes will be updated with the next deployment',
        });
      }

      yield put({ type: PublishingActions.SET_INITIAL_CONTENT_TO_PUBLISH });

      yield put({
        type: PublishingActions.SET_PUBLISHING_IN_PROGRESS,
        payload: false,
      });
    } catch (e: any) {
      const preparedContent: PreparedPublishContentInterface = yield select(selectPublishingPreparedContent);

      if (e?.response?.data?.detail?.includes('contains invalid Resource bundle')) {
        const mappingsForCurrentCourse: AxiosResponse = yield call(() =>
          apiClient.v2.get(`/components/${preparedContent.id}/mappings`),
        );

        yield put({
          type: PublishingActions.SET_INVALID_CHILDREN_MODAL_OPENED,
          payload: { opened: true, data: mappingsForCurrentCourse.data },
        });
      } else if (e?.response?.data?.detail?.includes('Some components need to be updated')) {
        yield put({
          type: PublishingActions.SET_INVALID_CHILDREN_MODAL_OPENED,
          payload: {
            opened: true,
            data: e.response.data.payload,
            errorInExercises: true,
          },
        });
      } else {
        addToast({
          type: 'error',
          title: `${e.response?.data?.detail}`,
        });
      }

      yield put({
        type: PublishingActions.SET_PUBLISHING_IN_PROGRESS,
        payload: false,
      });
      console.error(e);
    }
  },
};

export default PublishingSagas;
