import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { ProfileImage } from '@components/RolesAndPermissions/ProfileImage/ProfileImage';
import { TableItem } from '@components/RolesAndPermissions/RolesDescription/TableItem/TableItem';
import { Logo } from '@features/app/logo';
import { ALL_ROLES, Role } from '@features/auth/roles';
import { Button as Button2 } from '@features/theme';
import UsersService from '@services/UsersService';

import { useIsAdmin } from '@features/auth/roles';

export type RolesCountInterface = Record<Role, number>;

const ROLE_PERMISSIONS: { [key in Role]: string[] } = {
  ROLE_ADMIN: ['ALL'],
  ROLE_LOCALISATION_MANAGER: [
    'VIEW',
    'EDIT',
    'DOWNLOAD TRANSLATIONS',
    'UPLOAD TRANSLATIONS',
    'UPLOAD TRANSLATIONS TO SMARTLING',
    'PUBLISH',
    'UNPUBLISH',
  ],
  ROLE_PUBLISHER: ['VIEW', 'EDIT', 'DOWNLOAD TRANSLATIONS', 'UPLOAD TRANSLATIONS', 'PUBLISH', 'UNPUBLISH'],
  ROLE_UNPUBLISHER: ['VIEW', 'EDIT', 'DOWNLOAD TRANSLATIONS', 'UPLOAD TRANSLATIONS', 'UNPUBLISH'],
  ROLE_EDITOR: ['VIEW', 'EDIT', 'DOWNLOAD TRANSLATIONS', 'UPLOAD TRANSLATIONS'],
  ROLE_TRAINEE: ['VIEW', 'EDIT', 'DOWNLOAD TRANSLATIONS'],
  ROLE_VIEWER: ['VIEW'],
  ROLE_COURSE_STATS_VIEWER: ['VIEW'],
};

const ROLE_DESCRIPTIONS: { [key in Role]: string } = {
  ROLE_ADMIN:
    'Admins can create and edit content, edit default instructions, download translations, upload translations (including to Smartling), publish, and unpublish in all courses. They can also assign roles and courses to users.',
  ROLE_LOCALISATION_MANAGER:
    'Localisation Managers can create and edit content, download translations, upload translations (including to Smartling), publish, and unpublish content in the courses assigned to them by an Admin. In all other courses, they have view access only.',
  ROLE_PUBLISHER:
    'Publishers can create and edit content, download translations, upload translations (except to Smartling), publish, and unpublish content in the courses assigned to them by an Admin. In all other courses, they have view access only.',
  ROLE_UNPUBLISHER:
    'Unublishers can create and edit content, download translations, upload translations (except to Smartling) and unpublish content in the courses assigned to them by an Admin. In all other courses, they have view access only.',
  ROLE_EDITOR:
    'Editors can create and edit content, download translations, and upload translations (except to Smartling) in the courses assigned to them by an Admin. In all other courses, they have view access only.',
  ROLE_TRAINEE:
    'Trainees can create and edit content and download translations in the courses assigned to them by an Admin. In all other courses, they have view access only.',
  ROLE_VIEWER: 'Viewers have view access only.',
  ROLE_COURSE_STATS_VIEWER: 'Viewers who can only access to Course Stats',
};

const RolesDescriptionWrapper = styled.div`
  padding: 0 4rem;
  width: 100%;
`;

const RolesDescriptionHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-height: 5.5rem;
  margin-bottom: 6rem;
  margin-top: 4rem;
  width: 100%;

  .dropdown-menu.show {
    transform: translate3d(-220px, 45px, 0px) !important;
  }
`;

const RolesDescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colorV2.textPrimary};
`;

const RolesDescriptionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10rem;
`;

const RolesDescriptionTitle = styled.h2`
  font-weight: 800;
  font-size: 3rem;
  letter-spacing: -0.015em;
  margin-bottom: 1.2rem;
`;

const RolesDescriptionTable = styled.table`
  margin-bottom: 3rem;
  width: 100%;
`;

const RolesDescriptionTableHeader = styled.th`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorV2.textTertiary};
  padding: 1.2rem 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colorV2.uiDivider};
`;

export const RolesDescription = () => {
  const history = useHistory();

  const [isRolesLoading, setIsRolesLoading] = useState(false);
  const [rolesCount, setRolesCount] = useState<RolesCountInterface | null>(null);

  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (!isAdmin) {
      history.replace('/');
    }
  }, [isAdmin, history]);

  useEffect(() => {
    setIsRolesLoading(true);
    UsersService.getRoles().then((result) => {
      setRolesCount(result.data);
      setIsRolesLoading(false);
    });
  }, []);

  return (
    <RolesDescriptionWrapper>
      <RolesDescriptionHeader>
        <Logo size="large" withLink />
        <ProfileImage />
      </RolesDescriptionHeader>
      <RolesDescriptionContent>
        <BreadcrumbNavigation
          breadcrumbItems={[
            <span onClick={() => history.push('/admin-settings')}>Admin Settings</span>,
            <span>Roles & Permissions</span>,
          ]}
        />
      </RolesDescriptionContent>
      <RolesDescriptionTitleContainer>
        <RolesDescriptionTitle>Roles & Permissions</RolesDescriptionTitle>
        <Button2 size="M" onClick={() => history.push('/admin-settings')}>
          Go to Admin Settings
        </Button2>
      </RolesDescriptionTitleContainer>
      <RolesDescriptionTable>
        <thead>
          <tr>
            <RolesDescriptionTableHeader>Roles</RolesDescriptionTableHeader>
            <RolesDescriptionTableHeader>Permissions</RolesDescriptionTableHeader>
            <RolesDescriptionTableHeader>Assigned users</RolesDescriptionTableHeader>
            <RolesDescriptionTableHeader>Overwiew</RolesDescriptionTableHeader>
          </tr>
        </thead>
        <tbody>
          {ALL_ROLES.map((role) => (
            <TableItem
              key={role}
              role={role}
              permissions={ROLE_PERMISSIONS[role]}
              count={rolesCount?.[role] || 0}
              overview={ROLE_DESCRIPTIONS[role]}
              isRolesLoading={isRolesLoading}
            />
          ))}
        </tbody>
      </RolesDescriptionTable>
    </RolesDescriptionWrapper>
  );
};
