import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { TrueFalseExerciseActionsCreator } from '@actionCreators/TrueFalseExerciseActionsCreator';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { TranslationsPanelModes } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { TrueFalseAnswerModes, type TrueFalseAnswerModesType } from './index';
import { useAppDispatch } from '@redux/store';
import BidimensionalEditor from '@components/BidimensionalEditor/BidimensionalEditor';
import { BidimensionalEditorModes } from '@components/BidimensionalEditor/enums/BidimensionalEditorModes';
import { LockContent } from '@features/content/exercises';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';

import {
  FalseStatementButton,
  TrueFalseStatementButtons,
  TrueFalseStatementWrapper,
  TrueStatementButton,
} from './styles';

interface TrueFalseStatementViewProps {
  isInputErrorsShown: boolean;
  isAnswerErrorsShown: boolean;
  onButtonClick: (newState: TrueFalseAnswerModesType) => void;
  selectedButton: TrueFalseAnswerModesType | boolean | null;
  value: string | undefined;
  inputErrors: ValidationErrorInterface[];
  language: DisplayContentToUserModeType;
}

export const TrueFalseStatementView = ({
  isInputErrorsShown,
  isAnswerErrorsShown,
  onButtonClick,
  selectedButton,
  value,
  inputErrors,
  language,
}: TrueFalseStatementViewProps) => {
  const dispatch = useAppDispatch();

  return (
    <TrueFalseStatementWrapper>
      <TranslationsTipV2
        visitedBranch={'statement'}
        errors={inputErrors}
        showErrorDisplayer={false}
        showLanguageSwitcher={false}
        forTrueFalse={true}
        translationsPanelTranslationsMode={TranslationsPanelModes.wysiwyg}
      >
        <BidimensionalEditor
          dataSourceMode="defaultEditorState"
          ignoreLanguageForTips
          maxColums={1}
          mode={BidimensionalEditorModes.mono}
          language={DEFAULT_LANGUAGE_V2}
          singleLine
          storeBranch="statement"
          toolbar={{
            options: ['inline'],
            inline: {
              options: ['bold'],
            },
          }}
          toolbarCustomButtons={[<LockContent />]}
        />
      </TranslationsTipV2>

      <TrueFalseStatementButtons>
        <TrueStatementButton
          isAnswerErrorsShown={isAnswerErrorsShown}
          isSelected={`${selectedButton}` === TrueFalseAnswerModes.true}
          onClick={() => onButtonClick(TrueFalseAnswerModes.true)}
        >
          True
        </TrueStatementButton>
        <FalseStatementButton
          isAnswerErrorsShown={isAnswerErrorsShown}
          isSelected={`${selectedButton}` === TrueFalseAnswerModes.false}
          onClick={() => onButtonClick(TrueFalseAnswerModes.false)}
        >
          False
        </FalseStatementButton>
      </TrueFalseStatementButtons>

      <UserLanguageCheckbox
        checkedOverride={true}
        checked={false}
        disabled={true}
        checkedField={language}
        onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
          dispatch(TrueFalseExerciseActionsCreator.setStatementLanguage(displayContentToUserMode));
        }}
      />
    </TrueFalseStatementWrapper>
  );
};
