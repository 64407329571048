import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import type { CommonFilterOptionType } from '@components/SelectorCommonComponents';
import { useToast } from '@features/app/toast';
import { LANGUAGE_NAMES_V2, type LanguageV2 } from '@features/content/languages';
import { Text } from '@features/theme';

import { UploadTranslationsToLocalisationServiceService } from '../UploadTranslationsToLocalisationServiceService';
import { FilterContainer, StyledLanguageSelector } from './styles';
import { FiltersState } from '../types';
import { mapDataToFilterOption } from '../_util/mapDataToFilterOption';
import { DUE_DATE_IN_DAYS, DUE_DATE_IN_HOURS, REFRESH_CODE, REFRESH_LIST_OPTION } from './constants';
import { LocalisationJobFilter } from './LocalisationJobFilter';
import { LocalisationProjectFilter } from './LocalisationProjectFilter';
import { LocalisationDueDate } from './LocalisationDueDate';
import { normalizeDatetime } from '../_util/normalizeDateTime';

const FilterLabel = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();

  return (
    <Text color={theme.colorV2.textTertiary} level={3} spaceBottom="XS">
      {children}
    </Text>
  );
};

type LocalisationFiltersProps = {
  isOpen: boolean;
  filters: FiltersState;
  onCreateJob: (name: string) => void;
  onFilter: (
    filterName: keyof FiltersState,
    value: FiltersState['dueDate' | 'job' | 'projectId' | 'targetLanguages'],
  ) => void;
};

export const LocalisationFilters = ({ isOpen, filters, onCreateJob, onFilter }: LocalisationFiltersProps) => {
  const [localisationProjects, setLocalisationProjects] = useState<CommonFilterOptionType[]>([]);
  const [localisationJobs, setLocalisationJobs] = useState<CommonFilterOptionType[]>([]);
  const [isFetchingLanguages, setIsFetchingLanguages] = useState(false);
  const [isFetchingLocalisationProjects, setIsFetchingLocalisationProjects] = useState(false);
  const [isFetchingLocalisationJobs, setIsFetchingLocalisationJobs] = useState(false);

  const showToast = useToast();

  const fetchLocalisationProjects = ({ clearCache }: { clearCache: boolean }) => {
    setIsFetchingLocalisationProjects(true);

    UploadTranslationsToLocalisationServiceService.getLocalisationProjects({ clearCache })
      .then((result) => {
        const { data } = result;

        setLocalisationProjects([REFRESH_LIST_OPTION, ...mapDataToFilterOption(data)]);
        setIsFetchingLocalisationProjects(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchLocalisationJobsByProject = (projectId: string) => {
    setIsFetchingLocalisationJobs(true);

    UploadTranslationsToLocalisationServiceService.getLocalisationJobsByProject(projectId).then((result) => {
      const { data } = result;

      setLocalisationJobs([...mapDataToFilterOption(data)]);
      setIsFetchingLocalisationJobs(false);
    });
  };

  const presetDueDate = () => {
    const now = new Date();
    const presetDueDate = new Date(now.setDate(now.getDate() + DUE_DATE_IN_DAYS));
    const presetDueDateTime = new Date(presetDueDate.setUTCHours(DUE_DATE_IN_HOURS, 0, 0));

    onFilter('dueDate', normalizeDatetime(presetDueDateTime));
  };

  const preselectLanguages = (projectId: string) => {
    setIsFetchingLanguages(true);

    UploadTranslationsToLocalisationServiceService.getLanguagesByProject(projectId).then((result) => {
      const { data } = result;

      onFilter('targetLanguages', data);
      setIsFetchingLanguages(false);
    });
  };

  const handleOnLocalisationProjectFilter = (value: CommonFilterOptionType | null) => {
    if (value?.value === REFRESH_CODE) {
      fetchLocalisationProjects({ clearCache: true });
      onFilter('projectId', undefined);
      onFilter('job', {
        id: undefined,
        name: undefined,
      });
      return;
    }

    onFilter('projectId', value?.value);
  };

  const handleOnJobFilter = (value: CommonFilterOptionType | null) => {
    onFilter('job', {
      id: value?.value,
      name: value?.label,
    });
  };

  const handleOnCreateJob = (name: string) => {
    if (filters.projectId) {
      // Add the created job to the job filter options (first dropdown option is `refresh list`)
      const newLocalisationJobs = [...localisationJobs];
      newLocalisationJobs.splice(1, 0, { label: name, value: name });

      setLocalisationJobs(newLocalisationJobs);
      // Set the created job as selected
      onFilter('job', {
        id: name, // CreatableSearchableSelector constrain not allowing `undefined` or `null` for option value
        name,
      });
      // Execute callback
      onCreateJob(name);
    }
  };

  const showFilterError = useCallback(
    (description: string) => {
      showToast({
        type: 'error',
        title: 'Error',
        description,
      });
    },
    [showToast],
  );

  useEffect(() => {
    try {
      if (isOpen) {
        fetchLocalisationProjects({ clearCache: false });
        presetDueDate();
      }
    } catch (error) {
      showFilterError('An error occured when retrieving the Smartling projects');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, showFilterError]);

  useEffect(() => {
    try {
      if (filters.projectId) {
        fetchLocalisationJobsByProject(filters.projectId);
        preselectLanguages(filters.projectId);
      } else {
        setLocalisationJobs([]);
      }
    } catch (error) {
      showFilterError('An error occured when retrieving the jobs for the selected Smartling project');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.projectId, showFilterError]);

  return (
    <>
      <FilterContainer>
        <FilterLabel>Smartling project</FilterLabel>
        <LocalisationProjectFilter
          disabled={isFetchingLocalisationProjects}
          options={localisationProjects}
          value={
            localisationProjects.find((localisationProject) => localisationProject.value === filters.projectId) || null
          }
          onFilter={handleOnLocalisationProjectFilter}
        />
      </FilterContainer>
      <FilterContainer>
        <FilterLabel>Job name</FilterLabel>
        <LocalisationJobFilter
          disabled={!filters.projectId || isFetchingLocalisationJobs}
          options={localisationJobs}
          value={localisationJobs.find((localisationJob) => localisationJob.value === filters.job.id) || null}
          onCreate={handleOnCreateJob}
          onFilter={handleOnJobFilter}
        />
      </FilterContainer>
      <FilterContainer>
        <FilterLabel>Language groups</FilterLabel>
        <StyledLanguageSelector
          disabled={!filters.projectId || isFetchingLanguages}
          placeholder=""
          value={filters.targetLanguages.map((language) => ({ value: language, label: LANGUAGE_NAMES_V2[language] }))}
          onChange={(values) => onFilter('targetLanguages', values?.map(({ value }) => value as LanguageV2) ?? [])}
        />
      </FilterContainer>
      <FilterContainer>
        <FilterLabel>Due date</FilterLabel>
        <LocalisationDueDate value={filters.dueDate} onChange={(value) => onFilter('dueDate', value)} />
      </FilterContainer>
    </>
  );
};
