import styled from 'styled-components';

import { AnimatedLoader } from './AnimatedLoader';

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SizedLoader = styled(AnimatedLoader)<{ size?: 'S' | 'M' | 'L' }>`
  ${({ size }) => {
    if (size === 'L') {
      return 'width: 7.2rem;';
    }

    if (size === 'M') {
      return 'width: 3rem;';
    }

    if (size === 'S') {
      return 'width: 1.6rem;';
    }
  }}
`;

export const Loader = ({ className, size = 'M' }: { className?: string; size?: 'S' | 'M' | 'L' }) => (
  <LoaderWrapper className={className} data-testid="loader">
    <SizedLoader size={size} />
  </LoaderWrapper>
);
