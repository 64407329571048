export const ALL_ROLES = [
  'ROLE_ADMIN',
  'ROLE_LOCALISATION_MANAGER',
  'ROLE_PUBLISHER',
  'ROLE_UNPUBLISHER',
  'ROLE_EDITOR',
  'ROLE_TRAINEE',
  'ROLE_VIEWER',
  'ROLE_COURSE_STATS_VIEWER',
] as const;

export type Role = (typeof ALL_ROLES)[number];

export const ROLE_NAMES: { [key in Role]: string } = {
  ROLE_ADMIN: 'Admin',
  ROLE_PUBLISHER: 'Publisher',
  ROLE_UNPUBLISHER: 'Unpublisher',
  ROLE_EDITOR: 'Editor',
  ROLE_LOCALISATION_MANAGER: 'Localisation Manager',
  ROLE_TRAINEE: 'Trainee',
  ROLE_VIEWER: 'Viewer',
  ROLE_COURSE_STATS_VIEWER: 'Course Stats Viewer',
};

export * from './access';
