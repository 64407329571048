import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useIsPublishAvailable } from '@features/auth/roles';
import { CommonPublishButton } from '@features/content/header';
import type { LanguageV2 } from '@features/content/languages';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsPublishingInProgress } from '@selectors/CoursesSelectors';

import { LexicalItemContentType } from '../types';

export const PublishButton = ({
  contentToPublish,
  language,
}: {
  contentToPublish: LexicalItemContentType;
  language: LanguageV2;
}) => {
  const dispatch = useAppDispatch();

  const isPublishingInProgress = useAppSelector(selectIsPublishingInProgress);
  const { publish: isPublishAvailable, unpublish: isUnpublishAvailable } = useIsPublishAvailable();

  const isContentInvalid = !contentToPublish?.validationStatus?.valid;

  const isButtonDisabled = () => {
    if (contentToPublish.ready) {
      return false;
    }

    return isContentInvalid;
  };

  const handleButtonClick = (forUpdate: boolean) => {
    dispatch(
      PublishingActionsCreator.setContentToPublish(
        contentToPublish.id || '',
        ContentTypes.lexicalItem,
        contentToPublish.ready,
        forUpdate,
        undefined,
        undefined,
        language,
      ),
    );
  };

  return (
    <CommonPublishButton
      contentType="lexical item"
      isButtonDisabled={isButtonDisabled()}
      isPublishAvailable={isPublishAvailable}
      isPublishedContent={contentToPublish?.ready}
      isPublishingInProgress={isPublishingInProgress}
      isUpdateButtonDisabled={isContentInvalid}
      isUpdateVisible={contentToPublish?.ready && contentToPublish?.changeStatus.hasNewChanges}
      isUnpublishVisible={isUnpublishAvailable && contentToPublish?.ready}
      tooltipText=""
      onPublishClick={handleButtonClick}
    />
  );
};
