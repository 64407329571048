import { normalizeDatetime } from '../_util/normalizeDateTime';
import { DueDateInput } from './styles';

type LocalisationDueDateProps = {
  value: string | undefined;
  onChange: (value: string) => void;
};

export const LocalisationDueDate = ({ value, onChange }: LocalisationDueDateProps) => {
  const now = new Date(Date.now());
  const minimumDateTime = normalizeDatetime(now);

  return (
    <DueDateInput
      data-testid="due-date-selector"
      min={minimumDateTime}
      type="datetime-local"
      value={value}
      onChange={(evt) => onChange(evt.target.value)}
    />
  );
};
