import styled, { css } from 'styled-components/macro';

import { ReactComponent as ContextualMenuTriggerIcon } from './_img/context-menu-trigger.svg';

export const StyledContextualMenuTriggerIcon = styled(ContextualMenuTriggerIcon)`
  margin: 0 0.8rem;
  transform: scale(1.1);
  transform-origin: right;
`;

export const NewMark = styled.span`
  ${({ theme }) => css`
    align-self: center;
    background-color: ${theme.colorV2.newMark.background};
    border-radius: 0.4rem;
    color: ${theme.colorV2.textLight};
    font-size: 1rem;
    font-weight: 700;
    height: 1.8rem;
    line-height: 1.8rem;
    margin-left: ${theme.space.S};
    padding: 0 ${theme.space.S};
    text-transform: uppercase;
  `}
`;
