import type { CommonFilterOptionType } from '@components/SelectorCommonComponents';

import { StyledCommonFilterSelector } from './styles';

type LocalisationProjectFilterProps = {
  disabled: boolean;
  options: CommonFilterOptionType[];
  value: CommonFilterOptionType | null;
  onFilter: (value: CommonFilterOptionType | null) => void;
};

export const LocalisationProjectFilter = ({ disabled, options, value, onFilter }: LocalisationProjectFilterProps) => {
  return (
    <StyledCommonFilterSelector
      classNamePrefix="localisation-project-filter"
      disabled={disabled}
      options={options}
      placeholder=""
      value={value}
      onChange={onFilter}
    />
  );
};
