import { snakeCase } from 'lodash';
import { useState } from 'react';

import { useShowDebugUI } from '@features/app/debug';
import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { IssuesToggler } from '@components/IssuesToggler';
/** @TODO Refactor UploadTranslations component and move into @features/content/translations */
import { UploadTranslations } from '@components/UploadTranslations';
import { Logo } from '@features/app/logo';
import { ValidationButton } from '@features/content/forceValidation';
import { ButtonsList, HeaderItemSeparator, PendingChangesButton, SaveButton } from '@features/content/header';
import { UploadDownloadTranslationsMenu } from '@features/content/translations';
import ContentsService from '@services/ContentsService';

import { useVocabularyReview } from '../useVocabularyReview';
import { HeaderWrapper } from '../styles';
import { PublishButton } from './PublishButton';

export const Header = () => {
  const [showTranslationsUploadModal, setShowTranslationsUploadModal] = useState(false);

  const { contentTitle, interfaceLanguages, isLexicalItem, isNewLexicalItem, languageId, lexicalItem, onSave } =
    useVocabularyReview();
  const showDebugUI = useShowDebugUI();

  const isVocabularyReviewPage = languageId && !isLexicalItem;
  const lexicalItemId = lexicalItem.content.id;

  return (
    <>
      <HeaderWrapper>
        <Logo size="large" withLink={true} />
        <ButtonsList>
          {isVocabularyReviewPage || !isNewLexicalItem ? (
            <UploadDownloadTranslationsMenu
              language={languageId}
              showSeparator={false}
              onDownload={() => {
                ContentsService.csv.download(
                  lexicalItemId,
                  languageId,
                  interfaceLanguages,
                  snakeCase(contentTitle) || '',
                  ContentTypes.lexicalItem,
                );
              }}
              onUpload={() => setShowTranslationsUploadModal(true)}
              onUploadToLocalisationService={() => {}}
            />
          ) : null}

          {isLexicalItem && (
            <>
              {lexicalItem.loaded === LoadingStage.loaded ? (
                showDebugUI && languageId ? (
                  <ValidationButton id={lexicalItemId} type={ContentTypes.lexicalItem} />
                ) : (
                  <IssuesToggler
                    validation={lexicalItem.content.validationStatus}
                    type={ContentTypes.lexicalItem}
                    hasChildrenArrayInvalidItems={false}
                  />
                )
              ) : null}
              <HeaderItemSeparator />
              <PendingChangesButton />
              <SaveButton onSave={onSave} />
              <PublishButton contentToPublish={lexicalItem.content} language={languageId} />
            </>
          )}
        </ButtonsList>
      </HeaderWrapper>
      <UploadTranslations
        contentType={ContentTypes.lexicalItem}
        show={showTranslationsUploadModal}
        onHide={() => setShowTranslationsUploadModal(false)}
      />
    </>
  );
};
