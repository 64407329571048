import { ReactElement } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { PublishModalContainer } from '@components/CourseEdition/PublishModalContainer';
import { PublishingErrorsModal } from '@components/Publishing/PublishingErrorsModal/PublishingErrorsModal';
import { PublishingQueueSidebar } from '@components/Publishing/PublishingQueueSidebar';
import { layoutStyles } from '@features/app';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { useAppSelector } from '@redux/store';
import { selectIsSaveButtonEnabled } from '@selectors/CoursesSelectors';
import { selectPublishingPanelVisible } from '@selectors/UiSelectors';

export const VocabularyReviewPage = ({ children }: { children: ReactElement }) => {
  const history = useHistory();

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);
  const publishingPanelVisible = useAppSelector(selectPublishingPanelVisible);

  const { MainWrapper } = layoutStyles;

  if (!isFeatureEnabled('vocabularyReview')) {
    history.replace('/');
  }

  return (
    <MainWrapper fullWidth>
      <Prompt when={isSaveButtonEnabled} message={() => `Are you sure you want to navigate?`} />
      <PublishModalContainer />
      {publishingPanelVisible && <PublishingQueueSidebar />}
      <PublishingErrorsModal />
      {children}
    </MainWrapper>
  );
};
