import { AxiosResponse } from 'axios';
import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';

import { APIData, UploadTranslationsAPIParams } from './types';
import { LanguageV2 } from '@features/content/languages';

export const UploadTranslationsToLocalisationServiceService = {
  async getLocalisationProjects({ clearCache }: { clearCache: boolean }): Promise<AxiosResponse<APIData[]>> {
    return apiClient.noErrorsV2.get('translations/projects', { params: { clearCache } });
  },
  async getLanguagesByProject(projectId: DBId): Promise<AxiosResponse<LanguageV2[]>> {
    return apiClient.noErrorsV2.get(`translations/projects/${projectId}/languages`);
  },
  async getLocalisationJobsByProject(projectId: DBId): Promise<AxiosResponse<APIData[]>> {
    return apiClient.noErrorsV2.get(`translations/projects/${projectId}/jobs`);
  },
  async uploadTranslations(payload: UploadTranslationsAPIParams): Promise<AxiosResponse> {
    return apiClient.noErrorsV2.post('translations/requests', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};
